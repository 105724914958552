<template>
  <div>
    <!--    {
      "msg": "",
      "success": true,
      "code": 1000,
      "data": {
      "north": {
      "factory": {
      "total": 1200.0,
      "used": 100.0,
      "available": 1100.0,
      "vacancy_rate": 91.67,
      "rental_vacancy_rate": 91.67
      },
      "office": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      },
      "land": {
      "total": 414.0,
      "used": 202.0,
      "available": 212.0,
      "vacancy_rate": 51.21,
      "rental_vacancy_rate": 23.74
      },
      "warehouse": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      }
      },
      "south": {
      "factory": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      },
      "office": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      },
      "land": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      },
      "warehouse": {
      "total": 0,
      "used": 0,
      "available": 0,
      "vacancy_rate": 0,
      "rental_vacancy_rate": 0
      }
      }
      }
      }-->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="日期">
              <a-month-picker @change="onMonthPickerChange" :disabled-date="disabledDate" />
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-row id="cavancy_rate" v-if="false">
      <a-col :sm="11" :xs="24">
        <p style="width: 100px;margin: 0 auto;font-weight: bold; font-size:17px;">南区</p>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="厂房" bordered v-if="south">
          <a-descriptions-item label="总量" :span="3">
            {{ south.factory.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ south.factory.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ south.factory.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ south.factory.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ south.factory.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="办公室" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ south.office.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ south.office.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ south.office.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ south.office.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ south.office.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="地皮" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ south.land.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ south.land.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ south.land.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ south.land.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ south.land.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="仓库" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ south.warehouse.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ south.warehouse.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ south.warehouse.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ south.warehouse.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ south.warehouse.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>

      </a-col>
      <a-col :sm="11" :xs="24">
        <p style="width: 100px;margin: 0 auto;font-weight: bold; font-size:17px;">北区</p>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="厂房" bordered v-if="north">
          <a-descriptions-item label="总量" :span="3">
            {{ north.factory.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ north.factory.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ north.factory.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ north.factory.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ north.factory.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="办公室" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ north.office.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ north.office.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ north.office.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ north.office.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ north.office.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="地皮" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ north.land.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ north.land.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ north.land.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ north.land.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ north.land.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="margin-left: 60px;margin-top: 30px" title="仓库" bordered>
          <a-descriptions-item label="总量" :span="3">
            {{ north.warehouse.total + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="已使用" :span="3">
            {{ north.warehouse.used + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="可用数" :span="3">
            {{ north.warehouse.available + ' 平方米' }}
          </a-descriptions-item>
          <a-descriptions-item label="空置率" :span="3">
            {{ north.warehouse.vacancy_rate + ' %' }}
          </a-descriptions-item>
          <a-descriptions-item label="租金加权空置率" :span="3">
            {{ north.warehouse.rental_vacancy_rate + ' %' }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :showPagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="property_type" slot-scope="text">
        {{ dictionaries.locations_type[text.type] }}
      </div>
      <div slot="location" slot-scope="text">
        {{ dictionaries.locations[text.area] }}
      </div>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { cavancy_rate, property_statistics_history } from '@/api/zone_property'
import moment from 'moment/moment'
function mathround (obj) {
  if (obj != null) {
    const keys = Object.keys(obj)
    keys.forEach((key) => {
      console.log(Object.prototype.toString.call(obj[key]))
      if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
        mathround(obj[key])
      } else {
        obj[key] = obj[key].toFixed(2)
      }
    })
  }
}

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 查询参数
      queryParam: {},
      north: {
        'factory': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        },
        'office': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        },
        'land': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        },
        'warehouse': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        }
      },
      south: {
        'factory': {
          'total': 1200.0,
          'used': 100.0,
          'available': 1100.0,
          'vacancy_rate': 91.67,
          'rental_vacancy_rate': 91.67
        },
        'office': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        },
        'land': {
          'total': 414.0,
          'used': 202.0,
          'available': 212.0,
          'vacancy_rate': 51.21,
          'rental_vacancy_rate': 23.74
        },
        'warehouse': {
          'total': 0,
          'used': 0,
          'available': 0,
          'vacancy_rate': 0,
          'rental_vacancy_rate': 0
        }
      },
      columns: [
        {
          title: '总量(平方米)',
          align: 'center',
          dataIndex: 's_total'
        },
        {
          title: '已使用(平方米)',
          align: 'center',
          dataIndex: 's_used'
        },
        {
          title: '可用数(平方米)',
          align: 'center',
          dataIndex: 's_available'
        },
        {
          title: '空置率(%)',
          align: 'center',
          dataIndex: 'vacancy_rate'
        },
        {
          title: '租金加权空置率(%)',
          align: 'center',
          dataIndex: 'rental_vacancy_rate'
        },
        {
          title: '资产类型',
          dataIndex: 'property_type',
          // scopedSlots: { customRender: 'property_type' },
          customRender: (text) => this.dictionaries.property_type[text],
          align: 'center',
          ellipsis: true
        },
        {
          title: '位置',
          dataIndex: 'location',
          customRender: (text) => this.dictionaries.location.find((item) => !!item[text])[text],
          align: 'center',
          ellipsis: true
        },
        {
          title: '所属人',
          dataIndex: 'property_owner',
          customRender: (text) => this.dictionaries.property_owner[text],
          align: 'center'
        }
      ],
      loadData: parameter => {
        if (this.queryParam.year && this.queryParam.month) {
          this.loading = false
          return property_statistics_history(this.queryParam).then((res) => {
            return this.loadListData(res)
          })
        } else {
          return cavancy_rate(this.queryParam).then((res) => {
            return this.loadListData(res)
          })
        }
      }
    }
  },
  created () {
    // this.getInfo()
  },
  methods: {
    getInfo () {
      cavancy_rate().then(({ data }) => {
        var { north, south } = data
        mathround(north)
        mathround(south)
        console.log(north, south, 454654)
        this.north = data.north
        this.south = data.south
      })
    },
    onMonthPickerChange (date, dateString) {
      if (dateString === '') {
        this.queryParam.year = undefined
        this.queryParam.month = undefined
      } else {
        this.queryParam.year = dateString.split('-')[0]
        this.queryParam.month = dateString.split('-')[1]
      }
    },
    disabledDate (current) {
      console.log(typeof current, current.format('YYYY-MM') === this.getCurrentDate)
      // 2022-7 之前的不能选择；当月的不能选择，可选择当前月份之前的
      // console.log('当前时间', moment().format('YYYY-MM'), '是否在：', current.format('YYYY-MM'), '======之前：', moment().isBefore(current.format('YYYY-MM')))
      return (current && current.isBefore('2022-07')) || moment().isBefore(current.format('YYYY-MM')) || current.format('YYYY-MM') === this.getCurrentDate
    },
    searchHistory () {
      property_statistics_history(this.queryParam).then(({ data }) => {
        var { north, south } = data
        mathround(north)
        mathround(south)
        console.log(north, south, 454654)
        this.north = data.north
        this.south = data.south
      })
    },
    loadListData (res) {
      const myDataList = []
      const myData = {}
      if (res.code === 1000) {
        // const { north, south } = res.data
        // mathround(north)
        // mathround(south)
        // this.north = res.data.north
        // this.south = res.data.south
        // for (const area in res.data) {
        //   const location = res.data[area]
        //   for (const l in location) {
        //     const type = location[l]
        //     type.area = area
        //     type.type = l
        //     myDataList.push(type)
        //   }
        // }
        myData.entries = res.data
      }
      // myData.entries = myDataList
      console.log(myDataList, '-----------------------------')
      return myData
    }
  },
  computed: {
    getCurrentDate () {
      // eslint-disable-next-line no-extend-native
      Date.prototype.format = function (fmt) {
        const o = {
          'M+': this.getMonth() + 1,
          'd+': this.getDate(),
          'h+': this.getHours() % 12 === 0 ? 12 : this.getHours() % 12,
          'H+': this.getHours(),
          'm+': this.getMinutes(),
          's+': this.getSeconds(),
          'q+': Math.floor((this.getMonth() + 3) / 3),
          'S': this.getMilliseconds()
        }
        if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
        for (const k in o) {
          if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
          }
        }
        return fmt
      }
      const time = new Date().format('yyyy-MM')
      return time
    }
  }
}
</script>
<style>
  .ant-descriptions-item-content{
    text-align: right;
  }
</style>
